const VALID_URL_PATTERN =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#@!$&()*+;=%]+$/;

const INERTNAL_LINK_PATTERN = /^\/.*$/;

export const isValidURL = (string: string): boolean => {
    if (!string.includes('.')) {
        return INERTNAL_LINK_PATTERN.test(string);
    }
    return VALID_URL_PATTERN.test(string);
};

export const toSentenceCase = (str: string) => {
    const stringSentence = str[0]?.toUpperCase() + str.slice(1).toLowerCase();
    return stringSentence;
};
